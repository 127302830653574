import React, { useState, useEffect, useRef } from 'react'
import { AddTask } from '../modals/AddTask';
import * as s from './TasksView.styles';
import Card from './Card/Card';
// import customData from './testfile.json';

export default function TaskView() {
    const [showModal, setShowModal] = useState(false);
    const [taskList, setTaskList] = useState([]);
    // let dataFromJSON = "";
    var Emptymessage = "";
    const MidContainerRef = useRef(null);

    useEffect(() => {
        loadTasksFromLocalStorage();
    }, [])
    
    
    if(taskList.length !== 0){
        // console.log('empty message', Emptymessage);
        Emptymessage = "";
    }else{
        Emptymessage = "Task List is empty.";
    }

    function loadTasksFromLocalStorage() {
        let arr = localStorage.getItem("taskList");
        if(arr === null){
            //to avoid errors
            localStorage.setItem("taskList", JSON.stringify([]));
        }else{
            let loadedTasks =  JSON.parse(arr);
            setTaskList(loadedTasks);
        }
        }

    function deleteTask(index) {
        let filteredTasks = taskList.filter((Namedesc,i) => {
          return i !== index;
        });
      
        setTaskList(filteredTasks);
      
        localStorage.setItem("taskList",JSON.stringify(filteredTasks));
        }
    /*
    function updateListArray(obj,index){
        let tempList = taskList
        tempList[index] = obj;//in the same index it puts newlist
        localStorage.setItem("taskList", JSON.stringify(tempList))
        setTaskList(tempList)
        window.location.reload();
        //win reload
    }
    */
    /*
    const deleteTask2 = (index) => {
        let tempList = taskList;
        tempList.splice(index,1);
        setTaskList(tempList);
        localStorage.setItem("taskList",JSON.stringify(tempList));
    }
    */

    const openModal = () => {
        setShowModal(!showModal);
            // setShowModal(prev => !prev);
    };

    const saveTask = (taskObj) => {
        // /** used for defining ID for taskList
        const lastTask = taskList[taskList.length - 1];
        let newTaskId = 1;
        
        if (lastTask !== undefined) {
            newTaskId = lastTask.Id + 1;
        }
        //  */
        let tempList = taskList;
        // tempList.push(taskObj);//this pushes modal values to empty array
        //below adds values to what is already in tempList - here null
        tempList = [
            ...tempList,
            { 
                Id: newTaskId,
                Name: taskObj.Name,
                Description: taskObj.Description,
                TimerState: false,
                CardState: "ready",
                CardSeconds: 0
            },
          ];
        localStorage.setItem("taskList", JSON.stringify(tempList));
        setTaskList(tempList);
        setShowModal(false);//closes AddTask 
    }

    const exportList = (e) => {
        e.preventDefault();
        // let DB_taskList = taskList;
        //above taskList value doesn't have updated data..needs refresh maybe
        let DB_taskList = localStorage.getItem("taskList");
        var filename = "TaskList -" + new Date().toLocaleString() + ".json"
        // var filename = "TaskList -" + new Intl.DateTimeFormat('en-GB', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(Date.now()) + ".json"
        var down = document.createElement("a");
        //below was for removing slash in data
        // var stringedDB = DB_taskList;
        // var stringedDB = JSON.stringify(DB_taskList);
        // stringedDB = stringedDB.replace(/\\/g, ''); 
        console.log('Exported List-->',DB_taskList)
        down.setAttribute("href", "data:application/json;charset=utf-8," + DB_taskList);
        down.setAttribute("download", filename);
        if (document.createEvent && "download" in down && !window.navigator.msSaveBlob) {
            var event = document.createEvent("MouseEvents");
            event.initEvent("click", true, true);
            down.dispatchEvent(event);
        } else if("download" in down && !window.navigator.msSaveBlob) {
            down.click();
        } else if(window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(new Blob([DB_taskList], {type: "octet/stream"}), filename);
        }
    }
    
    const handleImportFile = () => {
        const fileSelector = document && document.querySelector('input'); 

        var files = fileSelector.files;
        // var finalData = "";
        var file = files[0];
        var fr = new FileReader();
        fr.onload = function(res) {
            var data = JSON.parse(res.target.result);
			if(Array.isArray(data)) {
                // console.log('obj is array');
                var tempList = taskList;
                for(var t = 0; t < data.length; t++) {
                    var Card = data[t];
					// var Id = Card.Id;
                    // used for defining ID for taskList
                    const lastTask = taskList[taskList.length - 1];
                    
                    let newTaskId = t + 1;
                    if (lastTask !== undefined) {
                        newTaskId = lastTask.Id + t + 1;
                    }
                    // console.log('Card>>',Card);  
                    tempList = [
                        ...tempList,
                        { 
                            Id: newTaskId,
                            Name: Card.Name,
                            Description: Card.Description,
                            TimerState: Card.TimerState,
                            CardState: Card.CardState,
                            CardSeconds: Card.CardSeconds
                        },
                    ];
                    // console.log('templist: ',tempList)
                    localStorage.setItem("taskList", JSON.stringify(tempList));
                }
            }
            window.location.reload()
            // console.log('taskList-->',taskList);
            // importList(data)
        }
        fr.readAsText(file);
        
    }
    return (
        <div style={{"height":"100vh"}}>
            <s.Container className="header">
                <s.AppTitleContainer>
                    {/* <h2>Task Tracker</h2> */}
                    <h2>Task tracker</h2>Made by Joben 
                </s.AppTitleContainer>
                <s.ContainerRightDiv>
                    {/* <s.Button onClick={importList}>Import</s.Button> */}
                    <s.ImportLabel 
                    for="file-selector"
                    >Import</s.ImportLabel>
                    <s.FileImportButton type="file" id="file-selector" onChange={handleImportFile} accept=".json"  multiple=""/>
                    <s.Button onClick={exportList}>Export</s.Button>
                </s.ContainerRightDiv>
            </s.Container>
            <s.TaskContainer>
                <s.MidTaskContainer ref={MidContainerRef}>

                {/* index for deleting */}
                {taskList && taskList.map((obj, index) => 
                <Card
                    taskObj={obj} 
                    index={index} 
                    deletetask={deleteTask}
                    // showTimer={true}
                    />
                    )}
                <div>{Emptymessage}</div>
                {/* //updateListArray={updateListArray}/>)} */}
                </s.MidTaskContainer>
            </s.TaskContainer>
            <s.Container className="header">
                <s.BottomTaskContainer>
                    <s.Button 
                    tabIndex={0}
                    onClick={openModal} 
                    // style={{"font-size":"24px"}}
                    className="addtask-Btn"
                    >Add new task</s.Button>
                    <AddTask 
                    showModal={showModal}
                    setShowModal={setShowModal} 
                    save={saveTask}
                    />
                </s.BottomTaskContainer>
            </s.Container>
        </div>
    )
}
