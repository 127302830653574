import React, {useRef, useEffect, useState, useCallback} from 'react'
// import {useSpring, animated} from 'react-spring'
import * as s from './Modal.styles'


export const AddTask = ({ showModal, setShowModal, save}) => {
    //useref for close button
    const modalRef = useRef();
    const NameRef = useRef(null);
    const DescriptionRef = useRef(null);

    const [taskName, setTaskName] = useState('');
    const [description, setDescription] = useState('');
    
    //as you type value gets updated 
    const handleChange = (e) => {
        const {name, value} = e.target;

        if(name === "taskName"){
            setTaskName(value);
        }else{
            setDescription(value);
        }
    }

    // below for closing modal on Background click
    //modalref.current here takes attribute of onclick in Background div
    const closeModal = e => {
        if(modalRef.current === e.target) {
            setShowModal(false);
        }
    }
    // // map of keyboard listeners
    // const keyListenersMap = new Map([
    //     [27, showModal],
    //     [9, handleTabKey],
    // ]);
    
    // function keyListener(e) {
    //     // get the listener corresponding to the pressed key
    //     const listener = keyListenersMap.get(e.keyCode);
    
    //     // call the listener if it exists
    //     return listener && listener(e);
    // }
    const handleTabKey = e => {
        if(modalRef.current) {
        const focusableModalElements = modalRef.current.querySelectorAll(
            'a[href], button, img,textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select, [tabindex]:not([tabindex="-1"])'
        );
        const firstElement = focusableModalElements[0];
        // const focusableContent = focusableModalElements;
        const lastElement = focusableModalElements[focusableModalElements.length - 1];
        /*
        if (!e.shiftKey && document.activeElement !== firstElement) {
          firstElement.focus();
          return e.preventDefault();
        }
        if (e.shiftKey && document.activeElement !== lastElement) {
          lastElement.focus();
          e.preventDefault();
        }*/
        let isTabPressed = e.key === 'Tab' || e.keyCode === 9;
        if (!isTabPressed) {return;}
        if (e.shiftKey) { // if shift key pressed for shift + tab combination
            if (document.activeElement === firstElement) {
            lastElement.focus(); // add focus for the last focusable element
            e.preventDefault();
            }
        } else { // if tab key is pressed
            if (document.activeElement === lastElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
            firstElement.focus(); // add focus for the first focusable element
            e.preventDefault();
            }
            }
        // firstElement.focus();  
        }
    }
    
    // const keyListenersMap = new Map([[27, showModal], [9, handleTabKey]]);
    
      
    //below for closing modal with esc key
    const keyPress = useCallback(e => {
        if(e.key === 'Escape' && showModal){
            setShowModal(false);
        }
    },[setShowModal,showModal])
    
    useEffect(()=>{
        document.addEventListener('keydown', keyPress);
        return() => document.removeEventListener('keydown',keyPress);    
    }, [keyPress])

    /**modalRef.current is null outside of modal. when tab presses */
    useEffect(() => {
        /*
        document.addEventListener("keydown", keyListener);
        function keyListener(e) {
          const listener = keyListenersMap.get(e.keyCode);
          return listener && listener(e);
        }
        document.addEventListener("keydown", keyListener);
    
        return () => document.removeEventListener("keydown", keyListener);
        */
        if(showModal){
            NameRef.current.focus();
        }
        document.addEventListener("keydown", handleTabKey);
        return () => document.removeEventListener("keydown", handleTabKey);
      },[showModal]);

    const firstKeyDown = e => {
        if(e.key === 'Enter'){
            DescriptionRef.current.focus();
        }
    }

    const handleSave = (event) => {
        event.preventDefault();

        //when value is empty
        if (taskName === ""){
            alert('Name cannot be empty');
            NameRef.current.focus();
            //add a div/show a div - required 
            return 0;
        }
        /*
        //below to validate description
        else if(description === ""){
            alert('Description cannot be empty');
            DescriptionRef.current.focus();
            //add a div/show a div - required 
            return 0;
            
        }
        */
        else{
            console.log("this function executed");
            let taskObj = {}
            // taskObj["id"] = Date.now();
            taskObj["Name"] = taskName;
            taskObj["Description"] = description;
            // taskObj["TimerState"] = "true";
            
            save(taskObj)//pushes obj in tasklist
            //above fn in taskview
        }

    }

    let t1 = (showModal) ? 1 : null;

    return (
    <>
    {showModal ? (
        <s.Background ref={modalRef} onClick={closeModal}>
            <s.ModalWrapper showModal={showModal}>
                {/* <s.ModalImg src={require('./modal.jpg')} alt='camera' /> */}
                <s.ModalHeader>
                    Add task
                    </s.ModalHeader>
                <s.ModalContent>
                    <s.form required>
                        <div>
                            <label>Name</label>
                            {/* optional: below maxLength="42"*/}
                            <input
                            tabIndex={t1}
                            type="text"
                            name="taskName"
                            value={taskName} 
                            // placeholder="Task Name" 
                            onKeyDown={firstKeyDown}
                            ref={NameRef}
                            onChange={handleChange}
                            required/>
                        </div>
                        <div>
                            <label>Description</label>
                            <textarea
                            tabIndex={t1+1}
                            rows = "5"
                            name="description"
                            value={description}
                            // placeholder="Description"
                            ref={DescriptionRef}
                            // onKeyDown={firstKeyDown}
                              onChange={handleChange}
                              required></textarea>
                        </div>
                    </s.form>
                </s.ModalContent>
                <s.ModalFooter>
                    <s.Button
                    tabIndex={t1+2}
                    onClick={handleSave}
                    >Create new task</s.Button>
                    {/* <s.Button onClick={() => setShowModal(!modalRef)} >Cancel</s.Button> */}
                </s.ModalFooter>
                <s.CloseModalButton
                    tabIndex={t1+3}
                    aria-label='close modal'
                    src = 'icons/close.svg'
                    onClick={() => setShowModal(!modalRef)}
                    />
            </s.ModalWrapper>
        </s.Background>
    ): null}
    </>
    )
};

//  default AddTask
