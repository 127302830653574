import styled from '@emotion/styled';

/* pass backgroun below as props to mainview */
export const Background = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4); 
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 100;
    /* width: 100vw;
    height: 100vh; */
`

export const ModalWrapper = styled.div`
    /* width: 500px; */
    padding: 10px 0px 10px 0px; //tlbl
    //space between border n content
    /**above clashes with underline */
    /* height: 250px; */
    box-shadow: 0 5px 16px rgba(0,0,0,0.5);
    color:#000;
    display:flex;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    z-index: 10;
    border-radius: 10px;
    /* background-color: #fff; */
    background-color: #ecf0f1;
    /* background-color: #f7e9d8; */
    /*
    *{
        background-color: #f7e9d8;
    }
    */
    object-fit: contain;
    /* max-width: 480px; */
    width: 100%;

    overflow-y: auto;
    max-width: 480px;
    /* max-height: 100%; */
    /* padding: 24px; */
    border-radius: 7px;
    /* background-color: var(--white); */
    box-shadow: 0 1px 4px rgb(0 0 0 / 9%), 0 3px 8px rgb(0 0 0 / 9%), 0 4px 13px rgb(0 0 0 / 13%);
    /* backface-visibility: hidden; */
    /* transform: translate3d(0, 0%, 0) scale3d(.6, .6, .6); */
    /* scrollbar-color: var(--scrollbar) transparent; */
    transition: opacity 200ms cubic-bezier(.165, .84, .44, 1) 0s,z-index 0s 100ms,visibility 0s 100ms,transform 100ms cubic-bezier(.165, .84, .44, 1) 0s,transform 100ms cubic-bezier(.165, .84, .44, 1) 0s;
    /* will-change: visibility,z-index,opacity,transform; */

    @media only screen and (min-width:320px) and (max-width: 550px){
         /* div{ */
             /* background-color: bisque; */
             /* width: fit-content; */
             width: 320px;
            /* width: 100%; */
            background-size: cover; 
            background-position: center center; 
            /* margin: 8px; */
            transition: transform 450ms;
         /* } */
    }
`


export const ModalHeader = styled.h3`
    height: 1px;
    /* width: 100%; */
    /* background-color: #e1e4eb; */
    margin-top: 25px;
    /* margin-bottom: .2rem; */
    /* padding: 10px 0px 8px 40px;//toprightbottomleft */
    padding: 0px 40px;
    display: flex;
    justify-content: left;
    /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
    font-weight: 500;
    user-select: none;
    white-space: nowrap;

    
    /* margin-bottom: 16px;
    color: #000;
    font-size: 2.07692308rem;
    font-weight: normal;
    line-height: 1.15384615; */

    //change below to & to unlock line below header
    &:after{
        content: '';
        /* position: absolute; */
        /* left: 0; */
        top: 60px;//space above line
        width: 50%;//100% of sidebar ...adjusts size according to it
        background: #c2c3c5;

        background-color: rgb(119, 153, 153);
        /* background-color: transparent; */
        border-radius: 10px;
        width: 80%;
        position: absolute;
        /**cannot use position because of modal. */
        /* display:flex; */
        /* justify-content: flex-start; */
        overflow: hidden;
        /* padding-bottom: 3px; */
        /* z-index: -2; */
        height: 2px;
        }
        
`
export const ModalImg = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 10px 0 0 10px;
    background: 000;
`
export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    line-height: 1.8;
    padding-top: 10px;
    margin-left: 40px;
    margin-right: 40px;
    color:#141414;
    height: 60%;
    /* width: 100%; */
    font-size: 1.2em;

    p{
        margin-bottom:1rem;
    }
    `;

export const form = styled.form`
    padding: 30px 0px 0px 0px; //tlbl
    /* background-color: skyblue; //to check size*/
    /* margin-right: 10px; */
    *{
        width:100%;
        margin-bottom: 0.1rem;
        font-size: 24px;
        font-family: inherit;
        resize: none;//makes textarea fixed
        /* float:right; */
        /* display:flex; */
        font-size: 18px;
        font-family: inherit;
    }
    label{
        /* float: left; */
        display: flex;
        justify-content: left;
        user-select: none;
    }
    input{
        padding:4px;
        cursor: text;
        border: 1px solid lightslategray;
        background-color: #ecf0f1;
        outline: none;
        &:focus{
            border: 2px solid #555;
        }
    }
    textarea{
        background-color: #ecf0f1;
        padding:4px;
        cursor: text;
        border: 1px solid lightslategray;
        outline: none;
        &:focus{
            border: 2px solid #555;
        }
    }
    
`

export const ModalFooter = styled.div`
    display: flex;
    /* flex-direction: column; */
    justify-content: right;
    align-items: center;
    line-height: 1.8;
    /* padding-top: 10px; */
    color:#141414;
    margin-bottom: .2rem;
    margin-right: 1.8rem;
`

export const Button = styled.button`
        margin: 10px;
        padding: 10px 24px;/*between text and border */
        background: #25668c;
        /* background: #141414; */
        color: #fff;
        border: none;
        border-radius: 10px;  
        font-size: 15px ;
        object-fit: contain;
        &:hover{
            opacity: 0.8;
            cursor: pointer;
            /* transition: .1s ease-in all;all - refer to all elements..here color */
        }
        /* background-color: ${props => props.theme.main}; */
        /* border: 2px solid ${props => props.theme.main}; */
        &#deleteBtn{
            border: 2px solid #f33a4c;
            background-color: #faa1aa;
            color: maroon;
            &:hover{
                background-color: #f33a4c;
                color: #fff;
                transition: 200ms;
            }
        }
`;


/* below was used for react-icon close button. */
/* use custom icon. import path */
export const CloseModalButton = styled.img`

    /* 
    position: absolute;
    top: 5%;
    right: 5%;
    z-index: 10;
    /* margin:1px; */
    /* background-color: red; */
    cursor: pointer;
    width: 25px;
    height: 25px;
    position: absolute !important;
    top: 8px;
    right: 8px;
    padding: 12px !important;
    /* tabIndex: -1; */
`;