import styled from '@emotion/styled';

export const TimerContainer = styled.div`
    white-space:nowrap;
    display: flex;
    justify-content: space-around;
`
export const ResetButton = styled.button`
    /* height:30px; */
    /* height:70px; */
    /* width: 180px;  */
    width: 80%; 
    top:0;
    border:none;
    /* border: 1px solid lightgray; */
    border-radius: 4px;
    padding: 0;
    margin: 2px;
    background: none;
    /* background-color: #141414; */
    color: #505050;
    /* font-weight: bold; */
    font-size: 19px;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
    /* background-color: lightslategray; */
    /* background-color: #f7e2d8; */
    /* opacity: 0.8; */
    white-space: nowrap;
    
    `
export const TimeButton = styled.button`
    /* margin: 10px; */
    /* padding: 10px 24px; */
    padding: 10px 15px;
    /*between text and border */
    color: #fff;
    border: none;
    border-radius: 25px;  
    /* font-size: 15px ; */
    object-fit: contain;
    background-color: #f33a4c;
    color: #f7f7f7;
    cursor: pointer;
    background-color: #ff8994;
    background-color: #5f9f5f;
    /* background: #141414; */
    /* opacity:0.8; */
    margin-left: 1%;
    &:hover{
        /* background-color: #f33a4c; */
        color: #fff;
        transform: scale(1.08);
        transform: translate3d(0, 0%, 0) scale3d(1.08, 1.08,1.08);
        transition: 200ms;
        transition: 120ms;
        
    }
`