import React from 'react';
// import "./Home.styles.css";
import TaskView from './TasksView';
/*
import StatusLine from "./StatusLine";
*/

function Home(){
    
    return (
        <div>
            {/* <h1 style={{"backgroundColor":"inherit","display":"block",
    "white-space": "nowrap"}}>Task tracker</h1>/ */}
            {/* <h1 style={{"position":"absolute","left":"120px","backgroundColor":"inherit","display":"block",
    "white-space": "nowrap"}}>Task tracker</h1> */}
            < TaskView />
            {/* <main>
                <section>
                    <StatusLine 
                    tasks={tasks}
                    addEmptyTask={addEmptyTask}
                    addTask={addTask}
                    deleteTask={deleteTask}
                    moveTask={moveTask}
                    status="Backlog"
                    />
                    <StatusLine 
                    tasks={tasks}
                    addEmptyTask={addEmptyTask}
                    addTask={addTask}
                    deleteTask={deleteTask}
                    moveTask={moveTask}
                    status="In Progress"
                    />
                    <StatusLine 
                    tasks={tasks}
                    addEmptyTask={addEmptyTask}
                    addTask={addTask}
                    deleteTask={deleteTask}
                    moveTask={moveTask}
                    status="Done"
                    />
                </section>
            </main> */}
        </div>


    )
}

export default Home
