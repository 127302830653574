import styled from '@emotion/styled';


export const DropDownContainer = styled.div`
    /* width: 100%; */
    /* height: 100%; */
    background: rgba(0,0,0,0.4); 
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border:1px solid lightgray;
    z-index: 100;



    background-color: #f7f7f7;
    border-radius: 8px;
    /* position: fixed; */
    position: absolute;
    /* top: 60px; */
    /* right: 0; */
    /* width: 8%;//value changes with responsive */
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    /* transform: translateY(42px); */
    /**use % and change foreach in media queries */
    /**get position if bottom show on top viceversa */
    transform: translate(10px,-25px);
    /* transform: translateX(-30px); */
    /**use media queries for different look */
    object-fit: contain;
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

    
    /* top: auto;
    left: auto;
    width: inherit; */
      
    

    ul {
    list-style: none;
    padding: 0;
    margin: 0;
    }

    li {
    border-bottom: 1px solid #dddddd;
    text-decoration: none;
    /* text-align: right; */
    color: #333333;
    padding: 10px 20px;
    display: block;
    cursor: pointer;
    /* cursor: default; */
    &:hover{
        // color: rgba(255,255,255);
        /* background-color: black; */
        background:rgba(198, 198, 198, 0.2);
        /* opacity: 0.4; */
    }
}

`



export const DropDownContainer2 = styled.div`    
    /* width: 500px; */
    padding: 10px 0px 10px 0px; //tlbl
    //space between border n content
    /**above clashes with underline */
    /* height: 250px; */
    box-shadow: 0 5px 16px rgba(0,0,0,0.5);
    color:#000;
    display:flex;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    z-index: 10;
    border-radius: 10px;
    /* background-color: #fff; */
    background-color: #ecf0f1;
    /* background-color: #f7e9d8; */
    /*
    *{
        background-color: #f7e9d8;
    }
    */
    object-fit: contain;
    /* max-width: 480px; */
    width: 100%;

    overflow-y: auto;
    max-width: 480px;
    /* max-height: 100%; */
    /* padding: 24px; */
    border-radius: 7px;
    /* background-color: var(--white); */
    box-shadow: 0 1px 4px rgb(0 0 0 / 9%), 0 3px 8px rgb(0 0 0 / 9%), 0 4px 13px rgb(0 0 0 / 13%);
    /* backface-visibility: hidden; */
    /* transform: translate3d(0, 0%, 0) scale3d(.6, .6, .6); */
    /* scrollbar-color: var(--scrollbar) transparent; */
    transition: opacity 200ms cubic-bezier(.165, .84, .44, 1) 0s,z-index 0s 100ms,visibility 0s 100ms,transform 100ms cubic-bezier(.165, .84, .44, 1) 0s,transform 100ms cubic-bezier(.165, .84, .44, 1) 0s;
    /* will-change: visibility,z-index,opacity,transform; */

    @media only screen and (min-width:320px) and (max-width: 550px){
         /* div{ */
             /* background-color: bisque; */
             /* width: fit-content; */
             width: 320px;
            /* width: 100%; */
            background-size: cover; 
            background-position: center center; 
            /* margin: 8px; */
            transition: transform 450ms;
         /* } */
    }
`
export const CardIcon = styled.img`
     cursor : pointer;
     height: 20px;
     width: 20px;
     margin-right: 10px;
`