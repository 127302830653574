import React from 'react'
import { Routes , Route, Navigate } from 'react-router-dom';

//Components
import Home from './components/MainView/Home/Home';
import About from './components/MainView/About/About';
// import AddTask from './components/MainView/AddTask/AddTask';
// import RemoveTask from './components/MainView/RemoveTask/RemoveTask';
import Report from './components/MainView/Report/Report';

const RoutesA = () => {
    return(
        <Routes>
            <Route exact path='/' element={<Home/>} />
            <Route path='/about' element={<About/>} />
            {/* <Route path='/addtask' element={<AddTask/>}/> */}
            <Route path='/report' element={<Report/>} />
            <Route element={<Navigate to="/" />} /> 
                {/*redirects to homepage */}
            
        </Routes>
    )
}

export default RoutesA;