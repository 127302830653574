import { useState, useEffect } from "react";

export const useDetectOutsideClick = (el, initialState) => {

    const [isActive,setIsActive] = useState(initialState);

    useEffect(() =>{
        // console.log('in useEffect', showDropdown)
        const pageClickEvent = (e) => {
        // dropdownRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) 

        

        // window.scrollTo(0, dropdownRef.current.offsetTop)
        // If the active element exists and is clicked outside of
        if(el.current !== null && !el.current.contains(e.target)){
            setIsActive(!isActive);
        }
        // else{
            //     works when you click in div
            //     const executeScroll = () => 
            //     dropdownRef.current.scrollIntoView()    
            //     console.log('entered else')
            // }
        };

        //If the item is active (ie open) then listen for clicks
        if (isActive){
            window.addEventListener('click', pageClickEvent);
            // document.addEventListener('touchmove', pageClickEvent);
        }

        return () => {
            window.removeEventListener('click',pageClickEvent);
            //     document.removeEventListener('touchmove', pageClickEvent);
        }
    },[isActive,el]);

    return [isActive,setIsActive];
}
