import React, { useState, useEffect, useRef } from 'react'
import DynamicFont from 'react-dynamic-font';
import * as s from './Card.styles'
import { EditTask } from '../../modals/EditTask';
import { Timer } from './Timer/Timer';
import { CardDropdown } from './CardDropdown/CardDropdown';
import { useDetectOutsideClick } from './useDetectOutsideClick';

const Card = (props) => {
    const { taskObj, index, deletetask } = props;

    const [showModal, setShowModal] = useState(false);
    const [showTimer, setShowTimer] = useState(taskObj.TimerState);//false
    //above gets true value when empty list loads up
    //cannot define true/false above because when page refreshes it needs updated value from db
    var [showCardState,setShowCardState] = useState(taskObj.CardState);//to sort on start
    

    const dropdownRef = useRef();//the component is passed to this variable
    // const [showDropdown, setShowDropdown] = useState(false);

    const [showDropdown, setShowDropdown] = useDetectOutsideClick(dropdownRef,false);

    /*const handleshowDropdown = () => {
        setShowDropdown(true);
        document.addEventListener('click',closeMenu);
    }
    const closeMenu = () => {
        setShowDropdown(false);
        document.removeEventListener('click',closeMenu)
    }*/



    /*
    const closeMenu = useCallback( e => {
    //     console.log('----in usecallback-----');
    //     // console.log('showDropdown', showDropdown);//true
    //     console.log('Callback dropdownRef', dropdownRef.current);
    //     console.log('Callback !dropdownRef', !dropdownRef.current);
        // console.log('e',e,'tar',e.target);
        // If the active element exists and is clicked outside of
        if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
              setShowDropdown(false);
            // console.log('showDropdownAfter', showDropdown, 'mr', dropdownRef.current);
        }
    }, [setShowDropdown])
*/
    /*
    //useeffect runs everytime on the dependency
*/


/**changed below useeffect to find top of dropdown. detecting outside clicks transferred to separate file */
useEffect(() => {
    // console.log('in useEffect', showDropdown)
    // If the item is active (ie open) then listen for clicks
    if (showDropdown) {
        //below scrolls to (center/top of div) to avoid showing half dropdown
        // console.log('offsetTop: ', dropdownRef.current.offsetTop);
        // console.log('boundingtop: ', dropdownRef.current.getBoundingClientRect().top);
        // console.log('height: ', window.innerHeight);
        //85 here is certain bounding value which changes on position of dropdown
        if(dropdownRef.current.getBoundingClientRect().top <= 85){
        // if(dropdownRef.current.offsetTop >= window.innerHeight){
        // if(dropdownRef){
            dropdownRef.current.scrollIntoView({ behavior: 'smooth', top: dropdownRef.current.offsetTop });
            // dropdownRef.current.scrollIntoView({ behavior: 'smooth',block: 'center',inline: 'center'});
        }
    }
}, [showDropdown]);

/*
    useEffect(() => {
        // console.log('in useEffect', showDropdown)
        const pageClickEvent = (e) => {
            // dropdownRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) 

            // window.scrollTo(0, dropdownRef.current.offsetTop)
            // If the active element exists and is clicked outside of
            if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
                setShowDropdown(!showDropdown);
            }
            // else{
            //     works when you click in div
            //     const executeScroll = () => 
            //     dropdownRef.current.scrollIntoView()    
            //     console.log('entered else')
            // }
        };
        // If the item is active (ie open) then listen for clicks
        if (showDropdown) {
            //below scrolls to (center/top of div) to avoid showing half dropdown
            // console.log('offsetTop: ', dropdownRef.current.offsetTop);
            // console.log('boundingtop: ', dropdownRef.current.getBoundingClientRect().top);
            // console.log('height: ', window.innerHeight);
            //85 here is certain bounding value which changes on position of dropdown
            if(dropdownRef.current.getBoundingClientRect().top <= 85){
            // if(dropdownRef.current.offsetTop >= window.innerHeight){
            // if(dropdownRef){
                dropdownRef.current.scrollIntoView({ behavior: 'smooth', top: dropdownRef.current.offsetTop }) 
                // dropdownRef.current.scrollIntoView({ behavior: 'smooth',block: 'center',inline: 'center'});
            }
        
            document.addEventListener('click', pageClickEvent);
            document.addEventListener('touchmove', pageClickEvent);
        }
        return () => {
            document.removeEventListener('click', pageClickEvent);
            document.removeEventListener('touchmove', pageClickEvent);
        }
    }, [showDropdown]);
    */
    /**changed closeMenu to pageClickEvent, removed closeMenu dependency */




    //code to show dropdown without overlapping .. not working
    // useEffect(() => {
    //     showDropdown && (document.body.style.overflow = 'hidden');
    //     !showDropdown && (document.body.style.overflow = "");
    //  }, [showDropdown ]);


    /**/
    /*
    useEffect(() => {
        console.log('showCardState', showCardState);
      }, [showCardState])
      */
    const handleHideButton = () => {
        // console.log("favoriteEvent---.", e);

        // below updates value with key
        setShowCardState("changedNotSorted");
        var taskList = JSON.parse(localStorage.taskList);

        for (var i = 0; i < taskList.length; i++) {
            if (taskObj.Id === taskList[i].Id) {
                taskList[i].TimerState = !showTimer;
                // console.log("!this.state.showTimerinloop---.",!showTimer );  
                taskList[i].CardState = "changedNotSorted";
                localStorage.setItem("taskList", JSON.stringify(taskList));
            }
        }
        setShowTimer(!showTimer);
        console.log("!state.showTimer---.", !showTimer);
        // setShowTimer("ready")
        // console.log("state.showTimer---.", showTimer); 
        console.log("Ready button switched off");
        // sortDatabase();
        window.location.reload();//refreshes timer.js with updated data >> reloads twice
    };

    function updateTask(newName, newDesc) {
        // event.preventDefault();
        var taskList = JSON.parse(localStorage.taskList);
        //below updates all tasks with that name/description...checks id

        for (var i = 0; i < taskList.length; i++) {
            if (taskObj.Id === taskList[i].Id) {
                taskList[i].Name = newName;
                taskList[i].Description = newDesc;
                localStorage.setItem("taskList", JSON.stringify(taskList));
            }
        }

        setShowModal(false);//closes EditTask 
        window.location.reload();
    }

    const handleDelete = () => {
        deletetask(index);
        //in taskview
    }

    const OpenEditfromDropdown = () => {
        // console.log('incard - set edit')
        setShowModal(true);
    }


    return (
        <s.CardWrapper 
        className="Checkerdiv">
            <s.TaskHolder>
                <s.CardHeader id="outer">
                    <DynamicFont content={taskObj.Name} />
                </s.CardHeader>
                {/* <s.CardTopBorder/> */}
                <s.CardBody>
                    {!showTimer && <s.TimerBtn onClick={handleHideButton}>Ready</s.TimerBtn>}
                    {showTimer ?
                        <Timer
                            showTimer={showTimer}
                            setShowTimer={setShowTimer}
                            taskObj={taskObj}
                            CardReady={true}
                            CardState={showCardState}
                        /> : null}
                    {/* {taskObj.Description} */}
                    {taskObj.id}
                </s.CardBody>
                {/* <s.CardTopBorder/> */}
                <s.CardFooter>
                    {/* <s.CardIcon
                        aria-label='edit icon'
                        src='icons/edit2.svg'
                        className="edit"
                        onClick={() => setShowModal(true)}
                    />
                    <s.CardIcon
                        aria-label='delete icon'
                        src='icons/delete2.svg'
                        className="delete"
                        onClick={handleDelete}
                    /> */}
                    <s.CardIcon
                        aria-label='dropdown icon'
                        src='icons/dropdown1.svg'
                        className="dropdown"
                        onClick={() => {
                            setShowDropdown(true);
                            // setShowDropdown(modalRef);
                        }}
                    />
                    {/* ᎒</s.CardIcon> */}
                    {/* >▼</button> */}

                    <CardDropdown
                        ref={dropdownRef}
                        showDropdown={showDropdown}
                        setShowDropdown={setShowDropdown}
                        OpenEditfromDropdown={OpenEditfromDropdown}
                        handleDelete={handleDelete}
                    //forward refs need props (so to make use we pass the showdropdown)
                    />
                </s.CardFooter>
            </s.TaskHolder>
            <s.editdiv>
                {/* pass here name and desc seperately? */}
                <EditTask
                    showModal={showModal}
                    setShowModal={setShowModal}
                    updatetask={updateTask}
                    taskObj={taskObj}
                />
            </s.editdiv>
        </s.CardWrapper>
    );
};

export default Card
