import styled from '@emotion/styled';


export const MainViewContainer = styled.div`
    width: 100%;/*80 - leaves space when sidebar closed*/
    /* position: relative; */
    margin: 0px auto;
    /* padding: 0px 20px; */
    /*above padding for gap at sides */
    /* max-width: 80%; */
    /* min-width: 80px; */
    /* padding: 10px; */
    text-align: center;

    // h1{
    //     font-size: 2rem;
    // }
    
`