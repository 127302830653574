import styled from '@emotion/styled';

export const editdiv = styled.div`
    /* margin:0;
    padding:0;
    box-sizing: border-box; */
`
export const CardWrapper = styled.div`
    /* margin:5px; */
    width: 250px; 
    height: 180px; 
    height: 10em; 
    position: relative;//used for attaching dropdown
    /* box-shadow: 0px 3px 10px #A5A5A5; */
    box-shadow:.1px 2px 1px #C8C8C8;
    /* box-shadow: 0 0 5px -1px rgba(0,0,0,0.2); */
    border-radius: 18px;
    
    border-radius: 10px;
    /* border-radius: 24px; */
    border: 1px solid lightgray;
    /**max. lighter color blends with bg */
    background-color: #f5e9d8;

    background-color: #f7f7f7;
    /* background-color: rgb(243, 213, 213); */
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /**space between cards-depends on taskview height */
    margin: 1.5% 1%;//tb lr
    /**margin affecting responsive design. fixed with div */
    @media only screen and (max-width:700px){
        margin: 5% 3%;
    }
    /** below active applies to edit modal */
    /*
    user-select: none;
    *{
        cursor: pointer;
    }
    &:active{
        box-shadow: 0 0 5px -1px rgba(0,0,0,0.6);
        *{
            color:gray;}
    }
    */
`

export const TaskHolder = styled.div`
    /* width: 100%; */
    height: 100%;
    padding-top: 10px;
    padding-bottom: 1px;
    padding-left: 10px;
    padding-right: 10px;
    /* padding for title,desc,button */
    display : flex;
    flex-direction: column;
    justify-content: space-evenly;
    justify-content: space-around;
    /* flex-direction: row; */
    /* justify-content: flex-start; */
    /* align-items: flex-start; */
    /* position: relative; */
    /* background-color: skyblue; */

    /**for react dynamic font */
    /* overflow: hidden; */
    `

export const CardTopBorder = styled.div`
    background-color: rgb(119, 153, 153);
    /* background-color: transparent; */
    border-radius: 10px;
    width: 220px;
    /* position: absolute; */
    /**cannot use position because of modal. */
    /* display:flex; */
    /* justify-content: flex-start; */

    overflow: hidden;
    /* padding-bottom: 3px; */
    /* z-index: -2; */
    height: 2px;
`
export const CardHeader = styled.div`
    /* background-color: rgb(119, 153, 153); */
    color: #202020;
    border-radius: 5px;
    /* border: 0.2px solid gray; */
    /* margin-top: 10px; */
    /* margin-bottom: 10px; */
    max-width: 200px;
    height: 1em;
    /* padding: 1px 1px !important; */
    /*to center text in */
    padding-top: .1em;
    padding-left: .5em;
    padding-bottom: 2px;
    text-align: left;
    &:hover{
        border-color: transparent;
        /* border:1px solid teal; */
    }
    /* for fade of title >> background: linear-gradient(rgba(0,0,0,0), #000); */
    /**below for fitting title */
    /* overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
    resize: none; */

    /* input is disabled. this one is div */
    cursor: default;
    font-size: 20px;
    font-weight: 180px;
    user-select: none;
`


export const CardBody = styled.p`
    /* margin-top: 3px; */
    /* border: 1px dotted gray; */
    /* padding: 10px; */
    /* height: 20%; */
    cursor: default;
    /* white-space: nowrap; */
`

export const TimerBtn = styled.button`
    /* height:30px; */
    /* width: 120px; */
    width: 50%;
    border:none;
    /* border-radius: 11px; */
    border-radius: 6px;
    /* border-radius: 15px; */
    padding: 5px 0px;
    margin: 0;
    /* background: none; */
    background-color: lightslategray;
    color: #fff;
    /* font-weight: bold; */
    font-size: 18px;
    text-align: top;
    /* font-size: medium; */
    /* font-size: 117%; */
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
    /* opacity: 0.8; */
    background-color: #83a87e;//green
    background-color: #5f9f5f;//green
    &:hover{
        /* background-color: #f33a4c; */
        color: #fff;
        transform: scale(1.08);
        transform: translate3d(0, 0%, 0) scale3d(1.08, 1.08,1.08);
        transition: 200ms;
        transition: 120ms;
        
    }

    
`
export const CardFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    /* background-color: rgb(119, 153, 153); */
    /* height: 40px; */
    /* width: 40px; */
`
export const CardIcon = styled.img`
     cursor : pointer;
     height: 20px;
     width: 20px;
     /* margin-right: 0px; */
     /* padding:2% 5%; */
     padding-top: 3%;
     padding-bottom: 3%;
     /* padding-left: 5%; */
     /* padding-right: 5%; */
     /* border: 1px solid black; */
`