import styled from '@emotion/styled';

/*easier to use this way instead of divs and classNames */
/*const ColorName = 'hexcode'   ....use props to use this*/
export const Container = styled.div`
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height:10%;
    /** connected to parent div */
    flex:1;
    /* width: 100%; */
    /* margin: 20px auto 0; */
    align-items: center;
    /* background-color: #e0e7f2; */
    /* background-color: #e5e5e5; */
    background-color: #fafafa;
    border:1px solid lightgray;
    object-fit: contain;
    font-size: 1em;
    @media only screen and (max-width: 460px){
        /* font-size: medium; */
    }
        `
export const ContainerRightDiv = styled.div`
    right:10px;
    position: absolute;
    @media only screen and (max-width: 460px){
        position: unset;
        transition: 250ms;
    }
`

export const AppTitleContainer = styled.div`
    left: 5%;
    position: absolute;
    user-select: none;
    color: navy;
`

export const Button = styled.button`
    text-decoration: underline;
    min-width:100px;
    padding: 1px 32px;
    /* padding: 16px 32px; */
    border-radius: 4px;
    border:none;
    /* border: .5px dotted gray; */
    /* background-color: #c5c5c5; */

    /* background-color: #e5e5e5;; */
    background-color: inherit;;
    /* color:darkslategray; */

    /* background: #141414; */
    /* color:#fff; */
    font-family: sans-serif;
    /* font-family: 'Courier New', Courier, monospace; */
    font-size:100%;
    cursor:pointer;
    white-space: nowrap;

    &.addtask-Btn{
        text-decoration: none;
        background-color: #3e3e3e;
        color:#fff;
        padding: 15%;
        /* height:100%; */
        /* position:absolute; */
        &:focus{
            opacity: 0.4;
        }
    }
`


export const ImportLabel = styled.label`
    cursor: pointer;
    /* border:1px solid black; */
    margin:10px;
    text-decoration: underline;
    padding: 10px 32px;
`



export const FileImportButton = styled.input`
    /* Force file picker to be hidden, but be interactable */
	/* position: absolute; */
	top: 0;
    background-color: red;
	right: 0;
	opacity: 0;
	-moz-opacity: 0;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
    /* width: 0.1px; */
	/* height: 0.1px; */
	/* opacity: 0; */
	overflow: hidden;
	position: absolute;
	z-index: -1;
    border: 1px solid black;

`


export const TaskContainer = styled.div`
    height: 80%;
     /**with other 20 - to parent div 100vh */
    /* width: 100%; */
    overflow-y: auto;
    /*display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex: auto; */
    /* flex-wrap: wrap; */
    /* background-color: #F6F7F8; */
    padding-left: .5%;
    padding-right:.5%;
    /* padding: 40px 40px; */
    /**above padding interfering with height */
    scrollbar-width: none;

    @media only screen and (max-width: 360px){
        object-fit: contain;
        padding: 0;
    }
`
export const MidTaskContainer = styled.div`
    /**this container div was made to avoid issues of card and container values */
    /* border: 1px dotted black; */
    /* top:0; */
    /* left:0; */
    /* width: 100%; */
    /* if(height<10){
        &:before { content: "Joe's Task:"; }
    } */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex: auto;
    flex-wrap: wrap;
    &::-webkit-scrollbar{
        display: none; //removes scrollbar if any. but keep scrolling function
    }
    padding-top: 2%;
    /* padding-left:0%; */
    padding-left: 8%;
    /**space around AllcardContainer */
    padding-bottom: 1%;
    /* padding-right: 100%; */
    background-color: #f2f2f2;
    /* background-color: #f00; */

    /**different media queries to center cards on resize */
    @media only screen and (max-width:660px){
        /* padding-left: 25%; */
        /* padding-right: 25%; */
        justify-content: center;
    }
    @media only screen and (max-width:980px){
        
        /* .Checkerdiv:nth-child(2n+1):last-child{
            //stretches last div if odd 
            background-color: teal;
            /* flex-grow:2; */
            /* justify-self: end; 
            
        }  */
    }
    `

export const BottomTaskContainer = styled.div`
    /* display: flex;
    justify-content: center; */
    margin : 0 auto;
`
