import React from 'react';
// import React,{useEffect} from 'react';
import * as s from './CardDropdown.styles';
// import { EditTask } from '../modals/EditTask';


export const CardDropdown = React.forwardRef((props, ref) => {

    const {
        setShowDropdown,
        showDropdown,
        OpenEditfromDropdown,
        handleDelete
    } = props;

    // transfer in parent
    // useEffect(() => {
    //     showDropdown && (document.body.style.overflow = 'hidden');
    //     !showDropdown && (document.body.style.overflow = "");
    //  }, [showDropdown ]);

    const editOption = () => {
        setShowDropdown(false);
        OpenEditfromDropdown();
    }
    return (
        // <h1>CardDropdown</h1>
        <>
            {showDropdown ? (
                <s.DropDownContainer ref={ref}>
                    <div onFocus="true"
                    // className={`menu ${showDropdown ? 'active' : 'inactive'}`}
                    >
                        <ul>
                            {/* <li>View</li> */}
                            <li
                                onClick={editOption}
                            >Edit</li>
                            <li onClick={handleDelete}>Delete</li>
                        </ul>
                    </div>
                    <div>
                        {/* pass here name and desc seperately? */}
                        {/* <EditTask
                    showModal={showModal}
                    setShowModal={setShowModal}
                    updatetask={updateTask}
                    taskObj={taskObj}
                /> */}
                    </div>
                </s.DropDownContainer>
            ) : null}
        </>

    )
});


// export default React.forwardRef(CardDropdown);
// export default CardDropdown;
