import React, { useState,useEffect,useCallback } from 'react';
import "./Timer.styles.css";
import * as s from './Timer.styles'
// import ReactTimeAgo from 'react-time-ago'

export function Timer(props){
    const {taskObj} = props;
    // var {showTimer} = props;
    // var {CardState} = props;

    // var CardStateRef = ref;
    
    // const CardStateRef = useRef(taskObj.CardState);
    //bring cardready as prop
    // const{CardReady,setCardReady} = useState(false); //used for sort after clicking ready

    const [seconds,setSeconds] = useState(taskObj.CardSeconds);
    // const [time,setTime] = useState(new Date());
    const [isRunning,setIsRunning] = useState(true);
    const [switcher,setSwitcher] = useState('');
    let dispSeconds = parseInt(("" + Math.floor((seconds)%60) ).slice(-2) );
    let dispMinutes = parseInt(("" + Math.floor((seconds%3600)/ 60) ).slice(-2));
    let dispHours = parseInt(("" + Math.floor((seconds/3600)) ).slice(-2));

    
   



    /**below delay put in variable . changes with time */
    // let displaytimeArr = [seconds,minutes]
    
    useEffect(()=>{
        if (isRunning){
            const id = window.setInterval(() => {
                setSeconds(seconds => seconds+1)   
            },1000);
            return () =>{
                window.clearInterval(id);
            } 
        }
        return undefined;
    },[isRunning]);

    


    useEffect(()=>{
        function handleUpdateDB(){
            var taskList = JSON.parse(localStorage.taskList);

            //do we use getItem?
            
            //below updates time...checks id

            for (var i = 0; i < taskList.length; i++) {
                if(taskObj.Id === taskList[i].Id){
                    taskList[i].CardSeconds = seconds;
                    // console.log("taskList",taskList);
                    localStorage.setItem("taskList", JSON.stringify(taskList));
                }
            }//endfor
        }
        //if pauseSwitch is on, do nothing else continue
        if(isRunning){
            handleUpdateDB()
            // console.log('seconds',seconds,'dispseconds',dispSeconds)  
            // below if 0 then can't reset properly 
            //onclick of reset. sets counter to 0...> used below if db gives >value
            if(seconds >= 1){
                setSwitcher('sec')
            }  
            if(seconds >= 60){
                // when reaches 60 seconds switch to min..
                // dispSeconds doesn't show 60. resets to 00
                setSwitcher('min');
            }
            if(seconds >= 3600){
                setSwitcher('hours');
            }
        }else{
            console.log('time paused');
            // console.log('sorting in progress. time paused awaiting new id');//paused so card seconds wont update wrong ID 
        }
        
    },[isRunning,seconds,dispSeconds,taskObj.Id]);
    
    const sortDatabase = useCallback(e => {
        // const times = Object.keys(localStorage)
        // .filter(key => key.length === 13 && !isNaN(new Date(key))
        // .map(key => key)
        // .sort() // alphabetic sort is ok on a timestamp

        // .map(key => ({[key]: localStorage[key] })));

        
        Object.entries = function( obj ){
            var ownProps = Object.keys( obj ),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array
            while (i--)
            resArray[i] = [ownProps[i], obj[ownProps[i]]];
            return resArray;
        };
        
        function sortByCardSeconds(taskList){
            const entries = Object.entries(taskList);
            // console.log('entries',entries);
            const sorted = entries.sort(function (a, b) {
                return a[1].CardSeconds - b[1].CardSeconds;
            });
            return sorted;
        }
        
        //as reset btn clicked set item of card to 0 - otherwise it takes previous values
        //reset to 1 since 0 is for cards with ready btn. while sorting 0 mixes between ready. so we use 1 
        var taskList = JSON.parse(localStorage.taskList);
        //do we use getItem?
        //below updates time...checks id
        for (var i = 0; i < taskList.length; i++) {
            if(taskObj.Id === taskList[i].Id){
                taskList[i].CardSeconds = 1; 
                // console.log("taskList",taskList);
                localStorage.setItem("taskList", JSON.stringify(taskList));
            }
        }//endfor

        
        // const taskList = JSON.parse(localStorage.taskList);

        console.log('reset btn pressed')

        // console.log("initial",taskList);
        // let arr4 = localStorage.getItem("taskList");
        // console.log('using getItem',arr4);
        // console.log("initial-type",typeof(taskList));

        const sortedList = sortByCardSeconds(taskList);
        // console.log('sortedlist', sortedList);

        const result = sortedList.filter(obj => {return obj});
        //below takes the object from the array --> [[0,{object}],[]]
        // console.log('result one one',result[0][1]);
        let tempList = [];
        let newId = 0;
        result.forEach((item,index) =>{

            // console.log(item[1],index); //item[1] is obj in array

            //assign new id in array.. when its obj. it assigns before fn to assign
            // console.log('id',item[1].Id);
            item[1].Id = newId;
            // console.log('new id',item[1].Id);
            newId++;
           
            tempList.push(item[1]);//pushes obj to new array
        });
        console.log('new sorted objects',tempList);
        
        //sort when pressing ready as well

        /*

        localStorage.setItem("taskList", JSON.stringify(tempList));
        */
        

        loadsortedTaskstoLocalStorage(tempList)
        //emptydb and add sorted directly??
            //every reset new nested array forms - fixed with above code


        //doesn't take current values. takes previous values.

        // /*
        function loadsortedTaskstoLocalStorage(sortedList){
            // let arr = localStorage.getItem("taskList");
            let arr = sortedList;
            localStorage.setItem("taskList", JSON.stringify([])); //empty db
            
            console.log('old removed');
            localStorage.setItem("taskList", JSON.stringify(arr)); 
            
            console.log('sorted db added')
            // let loadedTasks =  JSON.parse(arr);
            // setTaskList(loadedTasks);
        }
        window.location.reload();
    },[taskObj.Id])


    useEffect(()=>{
        // console.log("test");

        if(taskObj.CardState === "changedNotSorted"){
            console.log("cardstate>>",taskObj.Id,"+",taskObj.CardState);
            // setCardReady(false);
            taskObj.CardState = "Sorted";// to stop this snippet from running again
            console.log('after ready',taskObj.Id,"+",taskObj.CardState);
            sortDatabase();
            var taskList = JSON.parse(localStorage.taskList);
    
            //do we use getItem?
            
            //below updates time...checks id
    
            for (var i = 0; i < taskList.length; i++) {
                if(taskObj.Id === taskList[i].Id){
                    taskList[i].CardState = taskObj.CardState;
                    // console.log("taskList",taskList);
                    localStorage.setItem("taskList", JSON.stringify(taskList));
                }
            }//endfor
            window.location.reload();
        }

    },[taskObj.Id,taskObj,sortDatabase]);



        // */


        //below gives sort new ids
        /*
        if(Array.isArray(data)) {
            console.log('obj is array');
            var tempList = taskList;
            for(var t = 0; t < data.length; t++) {
                var Card = data[t];
                // var Id = Card.Id;
                // used for defining ID for taskList
                const lastTask = taskList[taskList.length - 1];
                
                let newTaskId = t + 1;
                if (lastTask !== undefined) {
                    newTaskId = lastTask.Id + t + 1;
                }
                console.log('Card>>',Card);  
                tempList = [
                    ...tempList,
                    { 
                        Id: newTaskId,
                        Name: Card.Name,
                        Description: Card.Description,
                        TimerState: Card.TimerState,
                        CardSeconds: Card.CardSeconds
                    },
                ];
                // console.log('templist: ',tempList)
                localStorage.setItem("taskList", JSON.stringify(tempList));
            }
        }
        window.location.reload()
        // console.log('taskList-->',taskList);
        */





        // /*
        /*
        Object.entries = function( obj ){
            var ownProps = Object.keys( obj ),
                i = ownProps.length,
                resArray = new Array(i); // preallocate the Array

            while (i--)
                resArray[i] = [ownProps[i], obj[ownProps[i]]];
            return resArray;
        };
        */
        /*
        let entries = Object.entries(taskList);
        let sorted = entries.sort(function (a, b) {
            
        // console.log("atype",typeof(a));
        // console.log("asectype",typeof(a.CardSeconds));
        // console.log("a0",a[0])
        // console.log("a1",a[1])
            
            return a[1] - b[1];
        });
        console.log("sorted",sorted);
        */

        // console.log("sorted Array test1:", taskList.sort());
        // let sort2 = taskList.sort(function(a, b) {
            //     return a.CardSeconds.localeCompare(b.CardSeconds);
            //     // return 0;
            //   });
            // console.log("sort2",sort2);
        /*let sort3 = taskList.sort(function(a, b) {
            let retk = 0;
            if (a.CardSeconds < b.CardSeconds) {
                // console.log("inside if");
                // console.log("a.sec",a.CardSeconds);
                // console.log("b.sec",b.CardSeconds);
                retk = (a.CardSeconds - b.CardSeconds)
                // console.log("retk value>>",retk);
            // return retk;
        }
        return retk;
        // else {
        //     return a.y - b.y;
        // };
        });
        console.log("sort3",sort3);
        */
        /*
        let sort4 = taskList.sort(function(a, b) {
            let retk4 = 0;
            if (a.CardSeconds !== b.CardSeconds) {
                // console.log("inside if");
                // console.log("a.sec",a.CardSeconds);
                // console.log("b.sec",b.CardSeconds);
                retk4 = (a.CardSeconds - b.CardSeconds)
                // console.log("retk4 value>>",retk4);
            // return retk4;
        }
        return retk4;
            // else {
            //     return a.y - b.y;
            // };
        });
        console.log("sort4",sort4);
        */
        // */

        // for (var i = 0; i < taskList.length; i++) {
        //     localStorage.setItem("taskList", JSON.stringify(taskList));
            
        // }

        // window.location.reload();
        
        // setShowModal(false);//closes EditTask 
        // arr.sortOnValue("value");
        // console.log(arr);
        // setTaskList(filteredTasks);
        // localStorage.setItem("taskList",JSON.stringify(filteredTasks));
         
    // }
    
    return (
        <>
            {/* <ReactTimeAgo date={time} locale="en-US" timeStyle="round"/> */}
            {/* <span>{ ("0" + Math.floor((seconds/60)% 60) ).slice(-2) }</span>: */}
            {/* <span>{dispSeconds}</span> */}
            <s.TimerContainer>

            <s.ResetButton onClick={() => {
                setSeconds(0);
                setIsRunning(true);
                setSwitcher('reset');
            }}>{
                {
                    
                    'sec': dispSeconds + (dispSeconds === 1 ? " second " : " seconds ") + 'ago',
                    'min': dispMinutes + (dispMinutes === 1 ? " minute " : " minutes ") + 'ago',
                    'hours': dispHours + (dispHours === 1 ? " hour " : " hours ") + 'ago',
                    'reset':'',
                }[switcher] || 'just now'
                // add a 'just now' option with if dispseconds == 01...and 1 minute/hour/month/year without s
            } 
            </s.ResetButton>
            {/* <div>{minutes}:{seconds} min ago</div> */}
            {/* <br/> */}
            {/*
            <button 
            className="start"
            onClick={() => {
                
                // timeChanger(seconds)
                setIsRunning(true);
                // setTime(new Date());
            }}
            >Start</button>
             { isRunning
                ?
                (
                    )
                    :(
                        <button className="start" onClick={() => {
                            setIsRunning(true);
                        }}>start</button>
                        )
                    } 
                    <button className="reset"
                    disabled={!isRunning} 
                    onClick={() => {
                        setSeconds(0);
                        setIsRunning(true);
                        setSwitcher('reset');
            }}>
            Reset & start
            </button>
            <button className="reset"
            disabled={!isRunning} 
            onClick={() => {
                setSeconds(0);
                setIsRunning(false);
                setSwitcher('reset');
            }}>
            Reset
            </button>
        */}
        <s.TimeButton className="pause" onClick={() => {
            setIsRunning(false);
        }}>P</s.TimeButton>
        <s.TimeButton 
            className="reset"
            // disabled={!isRunning} 
            onClick={() => {
                setSeconds(0);
                setIsRunning(false);//isrunning set to false to allow proper sort..refresh continues timer
                setSwitcher('reset');
                sortDatabase();
            }}>
            R
            </s.TimeButton>
        </s.TimerContainer>
            
        </>
    )
}

// export default Timer
