import styled from '@emotion/styled';

/*easier to use this way instead of divs and classNames */
/*const ColorName = 'hexcode'   ....use props to use this*/
export const App = styled.div`
    background: #ecf0f1;
    /*multiline comments - work here... no quontes for attributes*/
    height: 100vh; /*% shrinks to fit right content */
    display: flex;
    
    object-fit: contain;
`
    
export const Header = styled.div`
    color: pink
`