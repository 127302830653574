// import react from react;
import * as s from './App.styles';

// Components
//import Sidebar from './components/Sidebar/Sidebar'
import MainView from './components/MainView/MainView'

// can pass image path as a prop 

const App = () => {
  /*
  const backgroundImage = 'images/mountains.jpg';
  const guideIcon = 'icons/menu.svg';
  // const sidebarHeader = 'Task tracker';
  const sidebarHeader = {
    fullName: 'Task Tracker',
    shortName: 'TT'
  }
  // const menuItems = ['About', 'AddTask', 'Home', 'RemoveTasks', 'Report'];
  //follow guide for adding submenu array option
  //can add icon path later - maybe using react icons
  const menuItems = [
    {name: 'Home', to: '/', icon: 'icons/home.svg', subMenuItems: []},
    {name: 'Guide', to: '/about', icon: 'icons/about.svg', subMenuItems: []},
    // {name: 'AddTask', to: '/addtask', icon: 'icons/tools.svg', subMenuItems: []},
    // {name: 'RemoveTask', to: '/removetask', icon: 'icons/tools.svg', subMenuItems: []},
    {name: 'Report', to: '/report', icon: 'icons/report.svg', subMenuItems: []}
  ];
  */
  return (
    <s.App>
      {/* <Sidebar 
        backgroundImage ={backgroundImage}
        guideIcon ={guideIcon}
        sidebarHeader={sidebarHeader}
        menuItems={menuItems}
      /> */}
      <MainView />
    </s.App>
  );
}

export default App;
